html,
body {
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
}
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-right: 0px !important;
  overflow: hidden !important;
}
#root {
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
}
p,
label {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
input,
select {
  font-family: inherit;
  font-size: inherit;
}
